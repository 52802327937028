import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { AssignUnassignResponse } from 'state-domains/domain/configuration';
import { ActivityUser } from 'state-domains/domain/project';

import { buildConfigurationProjectActivityUsersUrl } from '../../../network/urls';
import { convertToCamel, convertToSnake, sendRequestWithXSRFToken } from '../../../utils';

export const modifyProjectActivityUserPermission = (
    projectId: string,
    activityId: string,
    payload: ActivityUser,
    userId = '',
): Observable<AssignUnassignResponse> => {
    const url = buildConfigurationProjectActivityUsersUrl(projectId, activityId, userId);
    return sendRequestWithXSRFToken(url, convertToSnake(payload), userId ? 'PUT' : 'POST').pipe(
        switchMap(({ response }: AjaxResponse<any>) => observableOf(convertToCamel<any>(response))),
    );
};
